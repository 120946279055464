// EXEMPT
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { IS_MAC } from "@/__main__/constants.mjs";
import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";
import router from "@/__main__/router.mjs";
import * as Styled from "@/app/AppShellTitleBar.style.jsx";
import {
  GAME_ACTIVE_MAP,
  GAME_ICONS,
  GAME_NAME_MAP,
} from "@/app/constants.mjs";
import appRefs from "@/app/refs.mjs";
import WindowControls from "@/app/WindowControls.jsx";
import { GAME_SYMBOL_LOL } from "@/game-lol/definition-symbol.mjs";
import ChevronLeft from "@/inline-assets/chevron-left-button.svg";
import ChevronRight from "@/inline-assets/chevron-right-button.svg";
import Reload from "@/inline-assets/reload-button.svg";
import SearchIcon from "@/inline-assets/search.svg";
import BlitzWordmark from "@/inline-assets/wordmark.svg";
import { showSearch } from "@/search/actions.mjs";
import { getSearchPlaceholder } from "@/search/util.mjs";
import Ripple from "@/shared/Ripple.jsx";
import { classNames } from "@/util/class-names.mjs";
import { useGameSymbol } from "@/util/game-route.mjs";
import globals from "@/util/global-whitelist.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const TitleBar = ({ ...props }) => {
  useRoute({ isGlobal: true });

  const { t } = useTranslation();

  const {
    appShell: { titleBarClasses },
  } = useSnapshot(appRefs);

  const {
    volatile: {
      runningGamesState: runningGames,
      currentGameTuple,
      currentSummoner,
      modalSearchVisible,
    },
  } = useSnapshot(readState);

  const draggable = !modalSearchVisible;

  const game = useMemo(() => {
    if (!runningGames) return null;

    const symbol = Object.getOwnPropertySymbols(runningGames).find(
      (e) =>
        runningGames[e] === true &&
        GAME_NAME_MAP[e] &&
        GAME_ACTIVE_MAP[e] === true,
    );

    if (symbol) return symbol;

    if (currentSummoner) {
      return currentGameTuple?.[0] || GAME_SYMBOL_LOL;
    }

    return null;
  }, [runningGames, currentGameTuple, currentSummoner]);

  const gameSymbol = useGameSymbol();

  const showGlobalSearch = useCallback(() => {
    showSearch();
  }, []);

  return (
    <Styled.Container
      {...props}
      data-draggable={draggable}
      {...classNames(props.className, ...titleBarClasses, IS_MAC && "mac")}
    >
      <div className="logo-container">
        <BlitzWordmark className="blitz-logo" />
      </div>
      <div className="contents">
        <Styled.HistoryBtns>
          <button onClick={() => history.back()} disabled={!router.canGoBack}>
            <ChevronLeft />
          </button>
          <button
            onClick={() => history.forward()}
            disabled={!router.canGoForward}
          >
            <ChevronRight />
          </button>
          <button
            onClick={() =>
              blitzMessage(EVENTS.BROWSERVIEW_RECREATE, globals.location.href)
            }
          >
            <Reload />
          </button>
        </Styled.HistoryBtns>

        <div className="address-bar">
          <button className="address-bar-btn" onClick={showGlobalSearch}>
            <Ripple />
            {game ? (
              <div className={"game"}>
                {" "}
                <img
                  data-tooltip={t(...GAME_NAME_MAP[game])}
                  src={GAME_ICONS[game]}
                />{" "}
              </div>
            ) : null}
            <SearchIcon />
            <span className="search type-form--button">
              {t(...getSearchPlaceholder(gameSymbol))}
            </span>
          </button>
        </div>
      </div>
      {!IS_MAC ? <WindowControls /> : <div className="spacer" />}
    </Styled.Container>
  );
};

export default TitleBar;
